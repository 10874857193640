import React from "react"
import { graphql } from "gatsby"
import Hero from 'src/components/StyledHero'
import Layout from 'src/components/layout'
import SEO from "src/components/seo"
import { rhythm } from "src/utils/typography"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const blogImg = data.file.childImageSharp.fluid

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Blog" />
      <Hero img={blogImg} />
        <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
            <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <AniLink fade style={{ boxShadow: `none` }} to={`/blog/${node.fields.slug}`}>
                  {title}
                </AniLink>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {type: {eq : "blog"}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    file (relativePath: {eq:"blogBcg.jpeg"}){
      childImageSharp {
        fluid (quality:90, maxWidth:4160){
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
